

import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {ref} from "vue";
import router from "@/router";
import Swal from "sweetalert2"
import SiteService from "@/service/siteService";

export default {
    data() {
        return {}
    },
    setup() {
        const store = useStore();
        let email = ref(null);
        let password = ref(null);

        const onSubmitLogin = async function () {
            await store.dispatch(Actions.LOGIN, {
                email: email.value,
                password: password.value
            });
            // const [errorName] = Object.keys(store.getters.getErrors);
            // const error = store.getters.getErrors[errorName];
            const error = store.getters.getErrors

            if (!error) {
                Swal.fire({
                    text: 'Problème de connexion, veuillez contacter l\'administrateur.',
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Réessayez!",
                    customClass: {
                        confirmButton: "btn fw-semobold btn-light-danger",
                    },
                });
            }
            if (!error?.length) {
                SiteService.destroyNameSite()
                SiteService.destroyTypeSite()
                SiteService.destroyIdSite()
                const path = localStorage.getItem('path')
                if (path) {
                    store.commit('setForcePath', true)
                    localStorage.removeItem('path')
                    // @ts-ignore
                    router.push(path)
                } else {
                    router.push({name: "dashboard"});
                }

            } else {
                Swal.fire({
                    text: 'Identifiants invalides.',
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Réessayez!",
                    customClass: {
                        confirmButton: "btn fw-semobold btn-light-danger",
                    },
                });
            }

        };
        return {
            store,
            onSubmitLogin,
            email,
            password
        }
    },
    methods: {
        resetPassword() {
            router.push({name: 'ui-ax-reset-password'})

        }
    }

}
