<template>
  <div class="topbar clearfix bar-top-box">
    <Dialog v-model:visible="isHotline" :style="{width: '900px'}" header="Question"
            :modal="true"
            class="modal_general p-fluid">

      <div class="formgrid grid">
        <div class="field-control col-12">
          <div>
            <Textarea :placeholder="t('yourMessage')" v-model="textAreaValue" rows="5" cols="50"></Textarea>
            <br />
            <br />
            <FileUpload ref="fileUploadDiscussion" name="fileArray[]" :multiple="true" :chooseLabel="t('importFiles')" :cancelLabel="''"/>
            <br />
            <br />
          </div>
        </div>
      </div>
      <template #footer>
        <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                @click="hideOpenDiscussion"/>

        <Button :disabled="textAreaValue === ''" :label="t('send')" icon="pi pi-check" class="p-button-text next_btn"
                @click="sendDiscussion()"/>
      </template>
    </Dialog>
    <div class="topbar-left">
      <img src="../public/layout/images/dashboard/logo_nv_white01.png" class="logo_desktop">
      <img src="../public/layout/images/dashboard/icone-logo-white.png" class="logo_mobile">
    </div>
    <div class="topbar-right">
      <button class="angle_desktop p-link" id="menu-button" @click="onMenuButtonClick">
        <i class="pi pi-angle-left"></i>
      </button>
      <!--			<button class="p-link" id="topbar-menu-button" @click="onTopbarMenuButtonClick">-->
      <!--				<i class="pi pi-bars"></i>-->
      <!--			</button>-->

      <div class="list_topbar_menu_mobile flex align-items-center justify-content-end">
        <span>{{ nameSite }}</span>
        <button class="p-link" id="topbar-menu-button" @click="toggleDataTable">
          <i class="pi pi-database"></i>
        </button>
        <!--        <Button type="button" label="DataTable" @click="toggleDataTable" class="p-button-success"/>-->
        <OverlayPanel ref="op2" appendTo="body" :showCloseIcon="true" id="overlay_panel" class="overlay_site_mobile"
                      style="width: 450px"
                      :breakpoints="{'960px': '75vw', '640px': '100vw'}">
          <TreeSelect v-model="selectedNode" :options="treeSelectNodes" placeholder="Select Item"
                      @change="siteChange()" @before-show="beforeShow()" @node-expand="beforeShow()"></TreeSelect>
        </OverlayPanel>
        <button id="topbar-menu-button" class="p-link mr-3">
          <i class="topbar-icon pi pi-fw pi-bell" v-badge.warning="4"></i>
        </button>
        <button class="p-link" id="topbar-menu-button" @click="onMenuButtonClick">
          <i class="pi pi-align-right"></i>
        </button>
      </div>
      <ul :class="topbarItemsClass">
        <li v-if="!user_hotline && is_site" :class="[{'active-top-menu': activeTopbarItem === 'hotline-question'}]"
            @click="$emit('topbar-item-click',{originalEvent:$event,item:'hotline-question'})" >
          <button class="p-link" @click="openDiscussion" :disabled="openDiscussionDisabled">
            <i class="topbar-icon pi pi-fw pi-comment" v-badge.warning="totalMessagesForum"></i>
            <span class="topbar-item-name">Notifications</span>
          </button>
        </li>

        <li v-if="profileMode === 'top' || horizontal"
            :class="['profile-item', {'active-top-menu': activeTopbarItem === 'profile'}]"
            @click="$emit('topbar-item-click',{originalEvent:$event,item:'profile'})">
          <button class="p-link">
            <Avatar label="P" class="mr-2" size="xlarge" shape="circle"></Avatar>
            <!--            <img alt="User" class="profile-image" src="layout/images/avatar.png"/>-->
            <div>
              <span class="topbar-item-name">Isabel Lopez</span>
              <span class="topbar-item-role">Marketing</span>
            </div>
          </button>
          <transition name="layout-submenu-container">
            <ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'profile'">
              <li role="menuitem">
                <button class="p-link topbar-item-button">
                  <i class="pi pi-fw pi-user"></i>
                  <span>Profile</span>
                </button>
              </li>
              <li role="menuitem">
                <button class="p-link topbar-item-button">
                  <i class="pi pi-fw pi-lock"></i>
                  <span>Privacy</span>
                </button>
              </li>
              <li role="menuitem">
                <button class="p-link topbar-item-button">
                  <i class="pi pi-fw pi-cog"></i>
                  <span>Settings</span>
                </button>
              </li>
              <li role="menuitem">
                <button class="p-link topbar-item-button">
                  <i class="pi pi-fw pi-sign-out"></i>
                  <span>Logout</span>
                </button>
              </li>
            </ul>
          </transition>
        </li>
        <!--				<li :class="[{'active-top-menu': activeTopbarItem === 'settings'}]"-->
        <!--					@click="$emit('topbar-item-click',{originalEvent:$event,item:'settings'})">-->
        <!--					<button class="p-link">-->
        <!--						<i class="topbar-icon pi pi-fw pi-cog"></i>-->
        <!--						<span class="topbar-item-name">Settings</span>-->
        <!--					</button>-->
        <!--					<transition name="layout-submenu-container">-->
        <!--						<ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'settings'">-->
        <!--							<li role="menuitem">-->
        <!--								<a href="#">-->
        <!--									<i class="pi pi-fw pi-palette"></i>-->
        <!--									<span>Change Theme</span>-->
        <!--								</a>-->
        <!--							</li>-->
        <!--							<li role="menuitem">-->
        <!--								<a href="#">-->
        <!--									<i class="pi pi-fw pi-star"></i>-->
        <!--									<span>Favorites</span>-->
        <!--								</a>-->
        <!--							</li>-->
        <!--							<li role="menuitem">-->
        <!--								<a href="#">-->
        <!--									<i class="pi pi-fw pi-lock"></i>-->
        <!--									<span>Lock Screen</span>-->
        <!--								</a>-->
        <!--							</li>-->
        <!--							<li role="menuitem">-->
        <!--								<a href="#">-->
        <!--									<i class="pi pi-fw pi-image"></i>-->
        <!--									<span>Wallpaper</span>-->
        <!--								</a>-->
        <!--							</li>-->
        <!--						</ul>-->
        <!--					</transition>-->
        <!--				</li>-->
        <li :class="[{'active-top-menu': activeTopbarItem === 'messages'}]"
            @click="$emit('topbar-item-click',{originalEvent:$event,item:'messages'})">
          <button class="btn-right-menu p-link">
            <i class="topbar-icon pi pi-fw pi-inbox" v-badge.warning="discussionsList?.length ? discussionsList?.length : 0"></i>
            <span class="topbar-item-name">Messages</span>
          </button>

          <transition v-if="discussionsList?.length > 0" name="layout-submenu-container">
            <ul class="layout-menu fadeInDown message-icon" v-show="activeTopbarItem === 'messages'" style="width: 400px">
              <li v-for="(discussion, key) of discussionsList" :key="key" role="menuitem"
                  @click="handleClickDiscussion(discussion)">
                <a class="topbar-message" href="#">
                  <strong v-if="discussion.is_hotline_readed === false">{{ discussion.topic && discussion.topic.substring(0, 20) }}</strong>
                  <span v-else>{{ discussion.topic && discussion.topic.substring(0, 52) + (discussion.topic.length > 50 ? '...' : '') }}</span>
                </a>
              </li>
              <li class="fixed-btn">
                <Button :label="t('seeAllMessages')" @click="handleSeePlus"/>
              </li>
            </ul>
          </transition>
          <transition v-else name="layout-submenu-container">
            <ul class="layout-menu fadeInDown message-icon" v-show="activeTopbarItem === 'messages'" style="width: 400px">
              <li  role="menuitem">
                  <span>{{t('NoDataToDisplay')}}</span>

              </li>
              <li>
                <Button :label="t('seeAllMessages')" @click="handleSeePlus"/>
              </li>
            </ul>
          </transition>
        </li>
        <li :class="[{'active-top-menu': activeTopbarItem === 'settings'}]"
            @click="$emit('topbar-item-click',{originalEvent:$event,item:'settings'})">
          <button class="p-link">
            <i class="topbar-icon pi pi-fw pi-globe"></i>
            <span class="topbar-item-name">Settings</span>
          </button>
          <transition name="layout-submenu-container">
            <ul class="multilang layout-menu fadeInDown" v-show="activeTopbarItem === 'settings'">
              <li class="menuitem lang" role="menuitem" @click="changeToFrench()">
                <a href="javaScript(0)">
                  <img src="demo/images/flags/pi-2.png" width="20">
                  <span>Français</span>
                </a>
              </li>
              <li class="menuitem lang" role="menuitem lang" @click="changeToEnglish()">
                <a href="javaScript(0)">
                  <img src="layout/images/pages/US.svg">
                  <span>English</span>
                </a>
              </li>
              <!--              <li role="menuitem">-->
              <!--                <a href="#">-->
              <!--                  <i class="pi pi-fw pi-lock"></i>-->
              <!--                  <span>Lock Screen</span>-->
              <!--                </a>-->
              <!--              </li>-->
              <!--              <li role="menuitem">-->
              <!--                <a href="#">-->
              <!--                  <i class="pi pi-fw pi-image"></i>-->
              <!--                  <span>Wallpaper</span>-->
              <!--                </a>-->
              <!--              </li>-->
            </ul>
          </transition>
        </li>
        <!--        <li :class="[{'active-top-menu': activeTopbarItem === 'notifications'}]"-->
        <!--            @click="$emit('topbar-item-click',{originalEvent:$event,item:'notifications'})">-->
        <!--          <button class="p-link">-->
        <!--            <i class="topbar-icon pi pi-fw pi-bell" v-badge.warning="1"></i>-->
        <!--            <span class="topbar-item-name">Notifications</span>-->
        <!--          </button>-->

        <!--          <transition name="layout-submenu-container">-->
        <!--            <ul class="layout-menu fadeInDown" v-show="activeTopbarItem === 'notifications'">-->
        <!--              <li role="menuitem">-->
        <!--                <a href="#">-->
        <!--                  <i class="pi pi-fw pi-calendar"></i>-->
        <!--                  <span>Import de textes terminé </span>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--              &lt;!&ndash;              <li role="menuitem">&ndash;&gt;-->
        <!--              &lt;!&ndash;                <a href="#">&ndash;&gt;-->
        <!--              &lt;!&ndash;                  <i class="pi pi-fw pi-calendar"></i>&ndash;&gt;-->
        <!--              &lt;!&ndash;                  <span>Meeting today at 3pm</span>&ndash;&gt;-->
        <!--              &lt;!&ndash;                </a>&ndash;&gt;-->
        <!--              &lt;!&ndash;              </li>&ndash;&gt;-->
        <!--              &lt;!&ndash;              <li role="menuitem">&ndash;&gt;-->
        <!--              &lt;!&ndash;                <a href="#">&ndash;&gt;-->
        <!--              &lt;!&ndash;                  <i class="pi pi-fw pi-calendar"></i>&ndash;&gt;-->
        <!--              &lt;!&ndash;                  <span>Meeting today at 3pm</span>&ndash;&gt;-->
        <!--              &lt;!&ndash;                </a>&ndash;&gt;-->
        <!--              &lt;!&ndash;              </li>&ndash;&gt;-->
        <!--              &lt;!&ndash;              <li role="menuitem">&ndash;&gt;-->
        <!--              &lt;!&ndash;                <a href="#">&ndash;&gt;-->
        <!--              &lt;!&ndash;                  <i class="pi pi-fw pi-calendar"></i>&ndash;&gt;-->
        <!--              &lt;!&ndash;                  <span>Meeting today at 3pm</span>&ndash;&gt;-->
        <!--              &lt;!&ndash;                </a>&ndash;&gt;-->
        <!--              &lt;!&ndash;              </li>&ndash;&gt;-->
        <!--            </ul>-->
        <!--          </transition>-->
        <!--        </li>-->
        <li>
          <TreeSelect v-model="selectedNode" :options="treeSelectNodes" placeholder="Accès aux bases"
                      @change="siteChange()"
          ></TreeSelect>
        </li>
        <li v-if="isSiteRTE" :class="[{'active-top-menu': activeTopbarItem === 'quiz'}]"
            @click="$emit('topbar-item-click',{originalEvent:$event,item:'quiz'})">
          <button class="btn_quiz p-link" @click="openQuiz" >
            <i class="topbar-icon pi pi-fw pi-question-circle"></i>
            <span>Questionnaire</span>
          </button>
        </li>
        <!--				<li :class="['search-item',{'active-top-menu': activeTopbarItem === 'search'}]"-->
        <!--					@click="$emit('topbar-item-click',{originalEvent:$event,item:'search'})">-->
        <!--					<div class="topbar-search">-->
        <!--						<input type="text" placeholder="Search"/>-->
        <!--						<i class="pi pi-search"></i>-->
        <!--					</div>-->
        <!--				</li>-->
      </ul>
    </div>
  </div>
  <Dialog v-model:visible="quizModel" :style="{width: '1200px'}" :header="t('quiz')" :modal="true"
          class="p-fluid quiz-modal">
    <TabView>
      <TabPanel v-for="(quiz, key) in quizData" :key="key">
        <template #header>
          <span>{{ key }}</span>
        </template>
        <div class="content_tab">
          <div v-for="(q, k) in quiz" :key="k" class="formgrid grid ">
            <div class="field col">
              <span>{{ q.question_label }}</span><br />
              <label for="titre">{{ q.obligation }}</label>
              <div class="field-radiobutton">
                <RadioButton :id="q.id + 'o'" name="Oui" :value="true" v-model="quizDataModel[q.id]" />
                <label :for="q.id + 'o'">Oui</label>
              </div>
              <div class="field-radiobutton">
                <RadioButton :id="q.id + 'n'" name="Non" :value="false" v-model="quizDataModel[q.id]" />
                <label :for="q.id + 'n'">Non</label>
              </div>
              <Textarea v-if="!['', '\r'].includes(q?.comment_question)" disabled="true" :id="q.id + 'd'" :value="q.comment_question" rows="5" cols="9" />
            </div>
          </div>
        </div>
      </TabPanel>
    </TabView>
    <template #footer>
      <div class="footer_quiz">
        <p><span v-if="last_update_question">{{t('DateOfLastQuestionnaireModification')}} {{formatDate(last_update_question)}} {{t('by')}} {{last_update_firstname}} {{last_update_lastname}}</span></p>
        <div>
          <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove" @click="cancelQuiz"/>
          <Button :disabled="saveQuizClicked" :label="t('save')"  icon="pi pi-check" class="p-button-text next_btn" @click="saveQuiz"/>
        </div>
      </div>


    </template>
  </Dialog>

  <Toast />
</template>
<script>
import {FilterMatchMode} from 'primevue/api';
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import SiteService from "@/service/siteService";
import TranslationService from "@/service/TranslationService";
import {computed} from "vue";
import {usePrimeVue} from "primevue/config";
import router from "@/router";
import HotlineService from "@/service/HotlineService";
import text from "quill/blots/text";
// import router from "@/router";
import fr from "@/assets/fr.json"
import en from "@/assets/en.json"

export default {
  data() {
    return {
      products: null,
      selectedProduct: null,
      treeSelectNodes: [],
      selectedNode: null,
      nameSite: '',
      accept: '',
      stopIt: false,
      dataSites: {},
      id_user: null,
      id_site: null,
      quizModel: false,
      quizData: [],
      quizDataModel: [],
      saveQuizClicked: false,
      discussionsListForum: [],
      discussionsList: [],
      discussionInterval: null,
      isHotline: false,
      textAreaValue: '',
      totalMessagesForum: 0,
      user_hotline: true,
      openDiscussionDisabled: false,
      verifSuperAdmin: false,
      is_site: false,
      isSiteRTE: false,
      last_update_question: null,
      last_update_firstname: null,
      last_update_lastname: null,
      isInitSiteRte: false,
    }
  },
  props: {
    topbarMenuActive: Boolean,
    profileMode: String,
    horizontal: Boolean,
    activeTopbarItem: String
  },
  setup() {
    // const changeToFrench = () =>{
    const primevue = usePrimeVue();
    // primevue.config.locale.accept = 'Aceptar';
    // }
    /* onMounted(() => {
       changeToFrench();
     })*/
    const store = useStore();
    const site = computed(() => {
      return store.getters.currentSite
    });
    const user = computed(() => {
      return store.getters.currentUser
    });
    const is_site_RTE = computed(() => {
      return store.getters.isSiteRte
    })
    const is_init_site_RTE = computed(() => {
      return store.getters.isInitSiteRte
    })

      if (store.state.currentLanguage === 'en')
          primevue.config.locale = en.en
      else
          primevue.config.locale = fr.fr

      return {
      store, site, primevue,user, t: primevue.config.StoreTranslation.t, is_site_RTE, is_init_site_RTE
    }
  },
  watch: {
    site() {
      this.getDiscussionList()
      this.id_site = this.site.id
      this.nameSite = SiteService.getNameSite();
      let typeSite = SiteService.getTypeSite();
      this.is_site = typeSite != 'SITE' ? false : true
      this.isSiteRTE = this.is_site_RTE
      this.isInitSiteRte = this.is_init_site_RTE
      setTimeout(() => {
        this.isRteAndInitSite(this.site?.id)
      }, "1000");

    },
    user() {
      this.id_user = this.user.id
      this.user_hotline = this.user.is_hotline
      if (this.user?.application_master) {
        this.verifSuperAdmin = true
      }
    },
    is_site_RTE(){
      this.isSiteRTE = this.is_site_RTE
    },
    is_init_site_RTE(){
      this.isInitSiteRte = this.is_init_site_RTE
    },

  },
  hotlineService: null,
  created() {
    this.hotlineService = new HotlineService()
    this.initFilters();
  },
  mounted() {
    // this.user_hotline = this.user.is_hotline
    this.isInitSiteRte = this.is_init_site_RTE
    this.isSiteRTE = this.is_site_RTE
    this.id_user = this.user.id
    this.user_hotline = this.user.is_hotline
    if (this.user?.application_master) {
      this.verifSuperAdmin = true
    }
    this.id_site = this.site.id
    let idSite = SiteService.getIdSite();
    this.nameSite = SiteService.getNameSite();
    let typeSite = SiteService.getTypeSite();
    this.is_site = typeSite != 'SITE' ? false : true
    this.store.commit(Mutations.SET_SITE, {
      id: idSite,
      name: this.nameSite,
      type: typeSite
    });
    this.selectedNode = {[idSite]: true}
    this.getSites()
    this.getDiscussionList()
    this.discussionInterval = setInterval(() => {
      this.getDiscussionList()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.discussionInterval);
  },
  methods: {
    formatDate(value) {
      if (value) {
        return new Date(value).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      } else {
        return '';
      }
    },

    cancelQuiz() {
      const hasNull = Object.values(this.quizDataModel).includes(null);
      if (hasNull) {
        this.$toast.add({severity: 'error', detail: this.t('userHasNotAnserToAllQuiz'), life: 5000});
        return
      }
      this.quizModel = false;
      // this.isInitSiteRte = false
    },
    saveQuiz() {
      const hasNull = Object.values(this.quizDataModel).includes(null);
      if (hasNull) {
        this.$toast.add({severity:'error', detail:this.t('userHasNotAnserToAllQuiz'), life: 5000});
        return
      }
      let data = []
      this.quizDataModel.forEach((value, index) =>  {
        if (value !== null) {
          data.push({
            "site_id": this.site.id,
            "user_id": this.user.id,
            "question_id": index,
            "response": value
          })
        }
      });
      if (data.length === 0) {
        this.quizModel = false;
        return
      }
      this.saveQuizClicked = true;
      this.store.dispatch(Actions.SAVE_QUIZ, {data: data}).then(data => {
        if (data.status === 201) {
          this.$toast.add({severity:'success', detail:this.t('userSaveQuiz'), life: 5000});
          this.$parent.checkInitSite(this.site)
          this.quizModel = false;
          this.$router.push('/');
        }
      })
    },
    isRteAndInitSite(id, forceRender = false) {
      if (!this.store.getters.isSiteRte) return false
      if (this.isInitSiteRte || forceRender) {
        this.store.dispatch(Actions.GET_QUIZ, {site_id: id}).then(data => {
          this.last_update_question = data[data.length-1]['last_update_question']
          this.last_update_firstname = data[data.length-1]['first_name']
          this.last_update_lastname = data[data.length-1]['last_name']
          data.pop()
          this.quizData = data.reduce((result, item) => {
            if (!result[item.category]) {
              result[item.category] = [];
            }
            result[item.category].push(item);
            return result;
          }, {})
          this.quizDataModel = []
          data.map((item, index) => {
            if (item?.site_questions?.length === 0) {
              this.quizDataModel[item.id] = null
            } else if(item?.site_questions){
              this.quizDataModel[item.id] = item?.site_questions[0].response
            }
            if (index === data.length - 1) {
              const hasNull = Object.values(this.quizDataModel).includes(null);
              if (hasNull || this.quizDataModel.length === 0) {
                this.$toast.add({
                  severity: 'info',
                  detail: this.t('userShouldAnserToQuiz'),
                  life: 5000
                });

              }
            }
          });
        })

        this.saveQuizClicked = false;
        this.quizModel = true;

      }
      return true
    },

    sendDiscussion() {
      let files = this.$refs.fileUploadDiscussion?.files
      let filedId = [];
      files.forEach((f, i) => {
        let json = {
          type: f.type,
          name: f.name,
          size: f.size,
          file: f,
        }
        this.hotlineService.uploadFile(json).then((data) => {
          if (data.status === 201) {
            filedId.push(data.data.id)
            if (i === files.length - 1) {
              this.postSendDiscussion(filedId)
            }
          }
        })
      })
      if (files.length === 0) {
        this.postSendDiscussion(filedId)
      }
    },
    postSendDiscussion(filedId) {
      let payload = {
        documentId: this.dataCurrentDiscussion?.id,
        topic: this.dataCurrentDiscussion?.title,
        message: this.textAreaValue,
        files: filedId,
        currentUserId: this.user.id,
        currentSiteId: this.id_site
      }
      this.hotlineService.sendDiscussion(payload).then((data) => {
        if (data.status === 201) {
          this.isHotline = false;
          this.textAreaValue = '';
        }
      })
    },
    hideOpenDiscussion() {
      this.isHotline = false;
      this.fileArray = [];
      this.textAreaValue = ''
    },
    openQuiz() {
      this.isRteAndInitSite(this?.site?.id, true)
    },
    openDiscussion() {
      // let payload = `?page=1&limit=1&type=forum&site=${this.site?.id}`
      this.openDiscussionDisabled = true
      if(this.id_site){
      this.hotlineService.getForum({customHeader: false, site: this.id_site, type: 'forum', page:1, limit: 1, user: this.id_user}).then((data) => {
        if (data.status === 200) {
          this.openDiscussionDisabled = false
          if (data.data.length > 0) {
            let element = data.data[0];
            this.$router.push({path: '/ui-ax/chat/' + element.id, params: {id: element.id, topic: element.data}});
          } else {
            this.$router.push('/ui-ax/chat');
          }
        }
      })
      }
    },
    handleClickDiscussion(data) {
      if (this.user.is_hotline && data.is_hotline_readed === false) {
        let payload = {
          "is_hotline_readed": true
        }
        this.hotlineService.readMessage(data.last_message_id, payload)
      }
      this.$router.push({path: '/ui-ax/chat/' + data.id, params: {id: data.id, topic: data.data}});
    },
    handleSeePlus() {
      this.$router.push('/ui-ax/discussions');
    },
    getSites() {

      this.treeSelectNodes = []
      let siteIds = []
      this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': false}).then(async data => {
        var formatSelectTree = (obj) => {
          obj?.forEach((element) => {
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens)
            }
            Object.assign(element, {"children": element.childrens})
            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
              siteIds.push(element.id)
            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"key": element.id})
          });
          return obj;
        };
        this.treeSelectNodes = await formatSelectTree(data);
        var filterSelectTree = (obj) => {
            obj?.forEach((element, index)=>{
                if(element.entity_type == 'entity' && !element.children){
                    obj.splice(index, 1)
                }
                if (Object.prototype.hasOwnProperty.call(element, 'childrens')){
                    filterSelectTree(element.childrens)
                }
            })
        }
        filterSelectTree(this.treeSelectNodes)
        // if (this.site.id && this.site.id !== 'null' && !siteIds.includes(parseInt(this.site.id))) {
        //   this.store.commit(Mutations.SET_SITE, {
        //     id: null,
        //     name: null,
        //     type: null,
        //   });
        //   this.$toast.add({
        //     severity: 'error',
        //     detail: this.t('siteAccessDeniedMsg'),
        //     life: 4000
        //   });
        //   setTimeout(() => {
        //     router.push({name: 'login'})
        //   }, 4000);
        //
        // }
        this.$emit('maDonneeChangee', this.treeSelectNodes);
      })
    },
    toggleDataTable(event) {
      this.$refs.op2.toggle(event);
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    siteChange: function () {
      let objectSite = {}
      var formatSelectTree = (obj, idSite) => {
        obj.forEach((element) => {
          if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
            formatSelectTree(element.childrens, idSite)
          }
          if (element.id == parseInt(idSite)) {
            objectSite = {id: element.id, name: element.name, type: element.entity_type}
            return objectSite
          }
        });
        return objectSite
      };
      let objSite = formatSelectTree(this.treeSelectNodes, Object.keys(this.selectedNode)[0])
      this.store.commit(Mutations.SET_SITE, {
        id: Object.keys(this.selectedNode)[0],
        name: objSite.name,
        type: objSite.type
      });
      this.$refs.op2.hide();
     router.push({name: "dashboard"});
    },
    onMenuButtonClick(event) {
      this.$emit('menubutton-click', event);
    },
    onTopbarMenuButtonClick(event) {
      this.$emit('topbar-menubutton-click', event);
    },
    changeToFrench() {
      this.store.state.currentLanguage = 'fr'
      this.primevue.config.locale = fr.fr;
      TranslationService.saveLAng('fr')
        this.$store.commit('setCurrentLanguage', 'fr')

    },
    changeToEnglish() {
      this.store.state.currentLanguage = 'en'
      this.primevue.config.locale = en.en;
      TranslationService.saveLAng('en')
        this.$store.commit('setCurrentLanguage', 'en')
    },
    beforeShow() {
    },
    // getStatusImport(){
    //   this.store.dispatch(Actions.GET_STATUS_IMPORT, {site_id: this.id_site ,'customHeader': true}).then(()=>{
    //   })
    // },
    getDiscussionList() {
      // let payload = `?site=${this.site.id}&page=0&limit=5`
      this.hotlineService.getDiscussionList({customHeader: false, site: this.site.id, type: 'forum', page:0, limit: 5}).then(data => {
        if (data?.status === 200) {
          this.discussionsListForum = data.data.discussions
          this.totalMessagesForum = data.data.discussions? data.data.discussions[0].total_messages : 0
        }
      }),

      this.hotlineService.getDiscussionList({customHeader: false, site: this.site.id, type: 'discussion', page:0, limit: 5}).then(data => {
        if (data?.status === 200) {
          this.discussionsList = data.data.discussions
        }
      })

    },


  },

  computed: {
    text() {
      return text
    },
    topbarItemsClass() {
      return ['topbar-items fadeInDown', {
        'topbar-items-visible': this.topbarMenuActive
      }];
    },

  },

}
</script>
<style lang="scss" scoped>
ul.layout-menu.fadeInDown.message-icon:before {
  left: 376px !important;
}
.content_tab {
  overflow-y: auto;
  height: 400px;
  padding-right: 15px;
}
.btn_quiz {
  display: flex !important;
  align-items: center;
  span {
    padding-left: 4px;
    color: white;
  }
}

ul.layout-menu.fadeInDown.message-icon {
  max-height: calc(100vh - 100px) !important;
  overflow: auto;
}
li.fixed-btn {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #fff;
  display: block;
  padding-bottom: 10px !important;
}
.btn-right-menu:after{
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 15px solid #3458a7;
  content: " ";
  position: absolute;
  top: 39px;
  right: 8px;
  opacity: 0;
  transform: translateY(-15px);
}
.active-top-menu .btn-right-menu:after{
  opacity: 1;
  transform: translateY(0);
  transition: .35s ease-in;
}
</style>
