<template>
  <div :class="containerClass" @click="onDocumentClick">
    <div class="layout-wrapper-content inner-novallia">
      <AppTopBar :key="store.getters.getTopBarKey" ref="AppTopBar" :topbarMenuActive="topbarMenuActive" :profileMode="profileMode"
                 :horizontal="layoutMode === 'horizontal'" :activeTopbarItem="activeTopbarItem"
                 @menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick"
                 @topbar-item-click="onTopbarItemClick"></AppTopBar>

      <transition name="layout-menu-container">
        <div :class="menuClass" @click="onMenuClick" v-show="isMenuVisible()">
          <div class="menu-scroll-content">
            <AppInlineProfile v-if="profileMode === 'inline' && layoutMode !== 'horizontal'"
                              @profile-click="onProfileClick" :expanded="profileExpanded"></AppInlineProfile>

            <AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive"
                     :mobileMenuActive="staticMenuMobileActive" @menuitem-click="onMenuItemClick"
                     @root-menuitem-click="onRootMenuItemClick"></AppMenu>

          </div>
        </div>
      </transition>

      <div class="layout-main">
        <router-view/>
      </div>

      <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" :darkMenu="darkMenu"
                 @menu-color-change="onMenuColorChange"
                 :profileMode="profileMode" @profile-mode-change="onProfileModeChange" :layout="layoutColor"
                 :layouts="layoutColors" @layout-color-change="onLayoutColorChange"
                 :theme="theme" :themes="themeColors" @theme-change="onThemeChange" :layoutScheme='layoutScheme'
                 @layout-scheme-change='changeLayoutScheme'></AppConfig>

      <!--			<div class="layout-mask"></div>-->

      <AppFooter/>
    </div>
  </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppConfig from './AppConfig.vue';
import AppInlineProfile from './AppInlineProfile.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import EventBus from './event-bus';
import {useStore} from "vuex";
import {computed, ref} from "vue";
// import router from "@/router";
import siteService from "@/service/siteService";
import {Actions} from "@/store/enums/StoreEnums";
// import UserService from "@/service/UserService";

export default {
  props: {
    theme: String,
    layoutColor: String,
    layoutScheme: String,
    darkMenu: Boolean,
  },
  data() {
    return {
      appTopBarKey: 0,
      layoutMode: 'static',
      profileMode: 'inline',
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      rotateMenuButton: false,
      menuActive: false,
      profileExpanded: false,
      themeColors: [
        {name: 'Blue', file: 'blue', color: '#0d6efd'},
        {name: 'Indigo', file: 'indigo', color: '#6610f2'},
        {name: 'Purple', file: 'purple', color: '#6f42c1'},
        {name: 'Pink', file: 'pink', color: '#d63384'},
        {name: 'Orange', file: 'orange', color: '#fd7e14'},
        {name: 'Yellow', file: 'yellow', color: '#ffc107'},
        {name: 'Green', file: 'green', color: '#198754'},
        {name: 'Teal', file: 'teal', color: '#20c997'},
        {name: 'Cyan', file: 'cyan', color: '#0dcaf0'},
      ],
      layoutColors: [
        {name: 'Blue', file: 'blue', color: '#0d6efd'},
        {name: 'Indigo', file: 'indigo', color: '#6610f2'},
        {name: 'Purple', file: 'purple', color: '#6f42c1'},
        {name: 'Pink', file: 'pink', color: '#d63384'},
        {name: 'Orange', file: 'orange', color: '#fd7e14'},
        {name: 'Yellow', file: 'yellow', color: '#ffc107'},
        {name: 'Green', file: 'green', color: '#198754'},
        {name: 'Teal', file: 'teal', color: '#20c997'},
        {name: 'Cyan', file: 'cyan', color: '#0dcaf0'},
      ],
      treeSelectNodes: [],
      listSiteByEntity: [],
    }
  },
  setup() {
    const menu = ref([
      {
        items: [
          {
            label: 'Tableau de bord', icon: 'pi pi-fw pi-home', to: '/'
          },
          {
            label: 'Tableau de bord ISO 50001', icon: 'pi pi-fw pi-bolt', to: '/dashboard/RATP'
          }
        ]
      },
      {
        label: 'NV Compliance', icon: 'pi pi-fw pi-star',
        items: [
          // {label: 'Base personnalisée', icon: 'pi pi-cog', to: '/formlayout', disabled: true},
          {label: 'Conformité', icon: 'pi pi-fw pi-check-square', to: '/ui-ax/Evaluation'},
          {
            label: 'Base personnalisée',
            icon: 'pi pi-fw pi-bookmark',
            to: '/peronnalised-base',
            // disabled: false
          },
          {label: 'Plans d’actions', icon: 'pi pi-fw pi-external-link', to: '/action-plan', disabled: false},
          // {label: 'Rapport', icon: 'pi pi-fw pi-clone', to: '/rapport', disabled: false},
          {label: 'Indicateurs', icon: 'pi pi-fw pi-mobile', to: '/rapport', class: 'rotated-icon', disabled: false},
          {label: 'Tâches en cours', icon: 'pi pi-fw pi-mobile', to: '/current_tasks', class: 'rotated-icon', disabled: false},
          {label: 'Base générale', icon: 'pi pi-fw pi-table', to: '/table', disabled: true},
          {
            label: 'Forum et Bibliothèque', icon: 'pi pi-fw pi-home',
            items: [
              {label: 'Bibliothèque documentaire', icon: 'pi pi-fw pi-home', to: '/dashboards/generic'},
              {label: 'Forum - contacts - RS', icon: 'pi pi-fw pi-money-bill', to: '/dashboards/dashboard_banking'},
            ], disabled: true,
          },
          // {
          //   label: 'Gestion des utilisateurs',  icon: 'pi pi-user', to: '/ui-ax/admin-user',class:'userManagement', style: ''
          // },
          // {
          //   label: 'Gestion des sites',  icon: 'pi pi-user', to: '/ui-ax/admin-site',class:'userManagement', style: ''
          // },
          {
            label: 'Gestion des clients', icon: 'pi pi-fw pi-globe', to: '/ui-ax/admin-customer', style: ''
          },
          {
            label: 'Interface de gestion', icon: 'pi pi-cog', style: '',
            items: [
              {label: 'Gestion des clients', icon: 'pi pi-fw pi-globe', to: '/ui-ax/admin-customer'},
              {label: 'Gestion des textes', icon: 'pi pi-fw pi-pencil', to: '/admin-text'},
              {label: 'Gestion des utilisateurs', icon: 'pi pi-user', to: '/ui-ax/admin-user'},
            ]
          },
        ]
      },


      // {
      //   label: 'Favorites', icon: 'pi pi-fw pi-home',
      //   items: [
      //     {
      //       label: 'Dashboards', icon: 'pi pi-fw pi-home',
      //       items: [
      //         {label: 'Generic', icon: 'pi pi-fw pi-home', to: '/dashboards/generic'},
      //         {label: 'Banking', icon: 'pi pi-fw pi-money-bill', to: '/dashboards/dashboard_banking'},
      //       ]
      //     }
      //   ]
      // },
      // {
      //   label: 'UI Kit', icon: 'pi pi-fw pi-star',
      //   items: [
      //     {label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout'},
      //     {label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input'},
      //     {label: 'Float Label', icon: 'pi pi-fw pi-bookmark', to: '/floatlabel'},
      //     {label: 'Invalid State', icon: 'pi pi-fw pi-exclamation-circle', to: '/invalidstate'},
      //     {label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button', class: 'rotated-icon'},
      //     {label: 'Table', icon: 'pi pi-fw pi-table', to: '/table'},
      //     {label: 'List', icon: 'pi pi-fw pi-list', to: '/list'},
      //     {label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree'},
      //     {label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel'},
      //     {label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay'},
      //     {label: "Media", icon: "pi pi-fw pi-image", to: "/media"},
      //     {label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menus'},
      //     {label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages'},
      //     {label: 'File', icon: 'pi pi-fw pi-file', to: '/file'},
      //     {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart'},
      //     {label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc'},
      //   ]
      // },
      // {
      //   label: "Utilities", icon: 'pi pi-fw pi-compass',
      //   items: [
      //     {label: 'Display', icon: 'pi pi-fw pi-desktop', to: '/display'},
      //     {label: 'Elevation', icon: 'pi pi-fw pi-external-link', to: '/elevation'},
      //     {label: 'Flexbox', icon: 'pi pi-fw pi-directions', to: '/flexbox'},
      //     {label: 'Icons', icon: 'pi pi-fw pi-prime', to: '/icons'},
      //     {label: 'Widgets', icon: 'pi pi-fw pi-star', to: '/widgets'},
      //     {label: 'Grid System', icon: 'pi pi-fw pi-th-large', to: '/grid'},
      //     {label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', to: '/spacing'},
      //     {label: 'Typography', icon: 'pi pi-fw pi-align-center', to: '/typography'},
      //     {label: 'Text', icon: 'pi pi-fw pi-pencil', to: '/text'},
      //   ]
      // },
      // {
      //   label: "UI Blocks", icon: "pi pi-building",
      //   items: [
      //     {label: "Free Blocks", icon: "pi pi-fw pi-eye", to: "/blocks", badge: "NEW"},
      //     {label: "All Blocks", icon: "pi pi-fw pi-globe", url: "https://www.primefaces.org/primeblocks-vue"}
      //   ]
      // },
      // {
      //   label: 'Pages', icon: 'pi pi-fw pi-briefcase',
      //   items: [
      //     {label: 'Crud', icon: 'pi pi-fw pi-pencil', to: '/crud'},
      //     {label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', to: '/calendar'},
      //     {label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline'},
      //     {label: 'Landing', icon: 'pi pi-fw pi-globe', url: 'pages/landing.html', target: '_blank'},
      //     {label: 'Login', icon: 'pi pi-fw pi-sign-in', to: '/login'},
      //     {label: 'Invoice', icon: 'pi pi-fw pi-dollar', to: '/invoice'},
      //     {label: 'Wizard', icon: 'pi pi-fw pi-star', to: '/wizard'},
      //     {label: 'Help', icon: 'pi pi-fw pi-question-circle', to: '/help'},
      //     {label: 'Error', icon: 'pi pi-fw pi-times-circle', to: '/error'},
      //     {label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', to: '/notfound'},
      //     {label: 'Access Denied', icon: 'pi pi-fw pi-lock', to: '/access'},
      //     {label: 'Empty', icon: 'pi pi-fw pi-circle-off', to: '/empty'}
      //   ]
      // },
      // {
      //   label: 'Hierarchy', icon: 'pi pi-fw pi-align-left',
      //   items: [
      //     {
      //       label: 'Submenu 1', icon: 'pi pi-fw pi-align-left',
      //       items: [
      //         {
      //           label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
      //           items: [
      //             {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left'},
      //             {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left'},
      //             {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left'},
      //           ]
      //         },
      //         {
      //           label: 'Submenu 1.2', icon: 'pi pi-fw pi-align-left',
      //           items: [
      //             {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-align-left'},
      //             {label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-align-left'}
      //           ]
      //         },
      //       ]
      //     },
      //     {
      //       label: 'Submenu 2', icon: 'pi pi-fw pi-align-left',
      //       items: [
      //         {
      //           label: 'Submenu 2.1', icon: 'pi pi-fw pi-align-left',
      //           items: [
      //             {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-align-left'},
      //             {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-align-left'},
      //             {label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-align-left'},
      //           ]
      //         },
      //         {
      //           label: 'Submenu 2.2', icon: 'pi pi-fw pi-align-left',
      //           items: [
      //             {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-align-left'},
      //             {label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-align-left'}
      //           ]
      //         },
      //       ]
      //     }
      //   ]
      // },
      // {
      //   label: 'Start', icon: 'pi pi-fw pi-download',
      //   items: [
      //     {
      //       label: 'Buy Now', icon: 'pi pi-fw pi-shopping-cart', url: 'https://www.primefaces.org/store'
      //     },
      //     {
      //       label: 'Documentation', icon: 'pi pi-fw pi-info-circle', to: '/documentation'
      //     }
      //   ]
      // }
    ]);

    const store = useStore();
    const id_site = computed(() => {
      return store.getters.currentSite.id
    });
    const site = computed(() => {
      return store.getters.currentSite
    });

    const user = computed(() => {
      return store.getters.currentUser
    });

    const isRatp = computed(() => {
      return store.getters.isRatp
    });

    const checkMenuAccess = () => {
      let obj = menu.value.find(x => x.label === 'NV Compliance')
      let objConf = obj.items.find(x => x.label === 'Conformité')
      let objConfPersonnalisedBase = obj.items.find(x => x.label === 'Base personnalisée')
      let objConfActionPlan = obj.items.find(x => x.label === 'Plans d’actions')
      let objConfRapport = obj.items.find(x => x.label === 'Indicateurs' ||  x.label === 'Indicateurs RTE')
      let objConfTasksPRocessing = obj.items.find(x => x.label === 'Tâches en cours')
      let typeSite = siteService.getTypeSite()
      objConfRapport['label'] = store.getters.isRte ?'Indicateurs RTE' : 'Indicateurs'
      let objDashboardRatp = menu.value[0]?.items?.find(x => x.label === 'Tableau de bord ISO 50001')

        objDashboardRatp['style'] =isRatp.value ? 'display:block' : 'display:none'

      if (!id_site.value || id_site.value == 'null' || store.getters.isInitSiteRte) {
        objConf['disabled'] = true
        objConfPersonnalisedBase['disabled'] = true
        objConfActionPlan['disabled'] = true
         objConfRapport['disabled'] = true
        objConfTasksPRocessing['disabled'] = true

      } else {
          objConfRapport['disabled'] = false
        if (typeSite != 'SITE') {
          objConf['disabled'] = true
          objConfPersonnalisedBase['disabled'] = true
          objConfActionPlan['disabled'] = typeSite == 'ENTITY' ? false : true
          objConfTasksPRocessing['disabled'] = typeSite == 'ENTITY' ? false : true
        } else {
          objConf['disabled'] = false
          objConfPersonnalisedBase['disabled'] = false
          objConfActionPlan['disabled'] = false
          objConfTasksPRocessing['disabled'] = false

        }
      }

    }

    checkMenuAccess();

    const checkMenuAccessUser = () => {

      let obj = menu.value.find(x => x.label === 'NV Compliance')
      let objConf = obj.items.find(x => x.label === 'Interface de gestion')
      let objConfCustomer = obj.items.find(x => x.label === 'Gestion des clients')


      if (user.value && user.value.application_master) {
        objConfCustomer['style'] = 'display:none'
        objConf['style'] = 'display:block'

      } else if (user.value.role_user && user.value.role_user[0] == 'ROLE_ADMIN' && !user.value.application_master) {
        objConfCustomer['style'] = 'display:none'
        objConf['style'] = 'display:none'
      } else if (user.value.role_user && user.value.role_user[0] == 'ROLE_ADMIN') {
        objConfCustomer['style'] = 'display:block'
        objConf['style'] = 'display:none'
      } else {
        objConfCustomer['style'] = 'display:none'
        objConf['style'] = 'display:none'

      }

    }
    checkMenuAccessUser

    return {store, id_site, user, menu, checkMenuAccess, checkMenuAccessUser, site};
  },

  watch: {
    site(newValue) {

      this.checkInitSite(newValue)
        // if(newValue.type?.toUpperCase() !== 'SITE'){
            this.checkListSitesByEntity(newValue.id)
        // }

    },
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();

      // if (siteService.getIdSite() || siteService.getIdSite() == 'null') {
      // } else {
      //   let obj = this.menu.find(x => x.label === 'NVSite')
      //   let objConf = obj.items.find(x => x.label === 'Conformité')
      //   objConf['disabled'] = true
      //
      //
      // }
    },

    id_site() {
      this.checkMenuAccess();
    },
    user() {
      this.checkMenuAccessUser()

      // if(this.user && this.user[0]!= 'ROLE_ADMIN' && !this.user.application_master){
      //
      //   objConf['disabled'] = true
      //   objConf['style'] = 'display:none'
      // }
      // else {
      //   objConf['disabled'] = false
      // }
    }

  },
  mounted() {
    this.checkMenuAccessUser();
    let obj = this.menu.find(x => x.label === 'NV Compliance')
    let objConf = obj.items.find(x => x.label === 'Conformité')
    if (siteService.getTypeSite() || siteService.getTypeSite() == 'null') {
      objConf['disabled'] = true
    }
  },
  methods: {
    async checkInitSite(newValue) {
      this.$store.commit('setSiteRte', false)
      if(newValue.type?.toUpperCase() != 'CUSTOMER'){
          await this.store.dispatch(Actions.GET_SITE_BY_ID, {id: newValue.id}).then(data => {
              this.$store.commit('setSelectedNode', data)
          })
      }
      else {
          this.$store.commit('setSelectedNode', {customer_id: newValue.id, type: 'CUSTOMER' })
      }
      this.checkMenuAccess()
    },
      async checkListSitesByEntity(entity) {
          this.treeSelectNodes = []
          let siteIds = []
          this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {'customHeader': false}).then(async data => {
              var formatSelectTree = (obj) => {
                  obj?.forEach((element) => {
                      if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
                          formatSelectTree(element.childrens)
                      }
                      Object.assign(element, {"children": element.childrens})
                      if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
                          Object.assign(element, {"label": element.site_name})
                          element.id = element.site_id
                          siteIds.push(element.id)
                      } else {
                          Object.assign(element, {"label": element.name})
                      }
                      Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
                      Object.assign(element, {"key": element.id})
                  });
                  return obj;
              };
              this.treeSelectNodes = await formatSelectTree(data);


              this.listSiteByEntity = []
              let exist = false
              let objectCurrentSite = []

              function getObjectCurrentSite(arr, id_site) {
                  arr?.forEach((obj) => {
                      if (obj.childrens && !exist) {
                          getObjectCurrentSite(obj.childrens, id_site, obj);
                      }
                      if (obj.childrens && obj.id == id_site) {
                          exist = true
                          objectCurrentSite = obj
                          return objectCurrentSite
                      }

                  });
                  return objectCurrentSite
              }

              objectCurrentSite = getObjectCurrentSite(this.treeSelectNodes, entity)
              const getSitesTree = (objectCurrentSite) => {
                  if (objectCurrentSite.childrens) {
                      objectCurrentSite.childrens.forEach(element => {
                          if (element.entity_type === 'site') {
                              this.listSiteByEntity.push(element.id);
                          } else {
                              getSitesTree(element);
                          }
                      });
                  }
              };
              getSitesTree(objectCurrentSite);
              this.$store.commit('setListSites', this.listSiteByEntity)
          })
    },
    changeToSpanish() {
      this.$primevue.config.locale.accept = 'Aceptar';
      this.$primevue.config.locale.reject = 'Rechazar';
    },
    onDocumentClick() {
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isHorizontal() || this.isSlim()) {
          this.menuActive = false;
          EventBus.emit('reset-active-index');
        }

        this.hideOverlayMenu();
      }

      this.topbarItemClick = false;
      this.menuClick = false;
    },
    onProfileClick(event) {
      this.profileExpanded = !this.profileExpanded;
      if (this.isHorizontal() || this.isSlim()) {
        EventBus.emit('reset-active-index');
      }

      event.preventDefault();
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit('reset-active-index');
        this.hideOverlayMenu();
      }
      if (!event.item.items && this.isHorizontal()) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;

      this.rotateMenuButton = !this.rotateMenuButton;
      this.topbarMenuActive = false;

      if (this.layoutMode === 'overlay') {
        this.overlayMenuActive = !this.overlayMenuActive;
      } else {
        if (this.isDesktop())
          this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        else
          this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }

      event.preventDefault();
    },
    onTopbarMenuButtonClick(event) {
      this.topbarItemClick = true;
      this.topbarMenuActive = !this.topbarMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === event.item)
        this.activeTopbarItem = null;
      else
        this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    hideOverlayMenu() {
      this.rotateMenuButton = false;
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isHorizontal() {
      return this.layoutMode === 'horizontal';
    },
    isSlim() {
      return this.layoutMode === 'slim';
    },
    isMenuVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuDesktopInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
        else
          return true;
      } else {
        if (this.staticMenuMobileActive)
          return true;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
        else
          return true;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
      this.staticMenuDesktopInactive = false;
      this.overlayMenuActive = false;

      if (this.isHorizontal()) {
        this.profileMode = 'top';
        this.profileMenuActive = false;
      }
    },
    onMenuColorChange(menuColor) {
      this.$emit('menu-color-change', menuColor);
    },
    changeLayoutScheme(scheme) {
      this.$emit('layout-scheme-change', scheme);
    },
    onProfileModeChange(profileMode) {
      this.profileMode = profileMode;
    },
    onLayoutColorChange(layout) {
      this.$emit('layout-change', layout);
    },
    onThemeChange(theme) {
      this.$emit('theme-change', theme);
    },
  },
  computed: {
    containerClass() {
      return ['layout-wrapper', {
        'menu-layout-static': this.layoutMode !== 'overlay',
        'menu-layout-overlay': this.layoutMode === 'overlay',
        'layout-menu-overlay-active': this.overlayMenuActive,
        'menu-layout-slim': this.layoutMode === 'slim',
        'menu-layout-horizontal': this.layoutMode === 'horizontal',
        'layout-menu-static-inactive': this.staticMenuDesktopInactive,
        'layout-menu-static-active': this.staticMenuMobileActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
    menuClass() {
      return ['layout-menu-container', {'layout-menu-dark': this.darkMenu}];
    }
  },
  components: {
    'AppTopBar': AppTopBar,
    'AppConfig': AppConfig,
    'AppInlineProfile': AppInlineProfile,
    'AppMenu': AppMenu,
    'AppFooter': AppFooter
  }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
