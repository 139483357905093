enum Actions {
    // action types
    ADD_BODY_CLASSNAME = "addBodyClassName",
    REMOVE_BODY_CLASSNAME = "removeBodyClassName",
    ADD_BODY_ATTRIBUTE = "addBodyAttribute",
    REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
    ADD_CLASSNAME = "addClassName",
    VERIFY_AUTH = "verifyAuth",
    LOGIN = "login",
    LOGOUT = "logout",
    REGISTER = "register",
    UPDATE_USER = "updateUser",
    FORGOT_PASSWORD = "forgotPassword",
    FORGOT_PASSWORD_RESET_PWD = "forgotPasswordResetPWD",
    SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
    SET_THEME_MODE_ACTION = "setThemeModeAction",
    GET_USER_BY_TOKEN = "getUserByToken",
    GET_SITES = "getSites",
    GET_SITES_STAT = "getSitesStat",
    GET_SITES_ALL = "getAllSites",
    GET_CUSTOMERS_SITES = 'getCustomersSites',
    GET_CUSTOMERS_BY_ID = 'getCustomersById',
    GET_CUSTOMERS_SITE_TREE = 'getCustomersSiteTree',
    ADD_CUSTOMER = 'addCustomer',
    PUT_CUSTOMER = 'editCustomer',
    GET_COUNTRIES = 'getCountries',
    GET_CATEGORIES = 'getCategories',
    GET_CATEGORIES_TREE = 'getCategoriesTree',
    ADD_SITE = 'addSite',
    GET_SITES_BY_CUSTOMER = "getSitesByCustomer",
    PUT_SITE = "editSite",
    GET_USERS = "getUsers",
    PUT_USER = 'editUser',
    ADD_USER = 'addUser',
    GET_USER_BY_ID = 'getUserById',
    DELETE_USER = 'deleteUser',
    GET_DOCUMENTS = "getDocuments",
    GET_DOCUMENTS_STAT = "getDocumentStat",
    GET_DOCUMENT_BY_ID = "getDocumentById",
    ADD_DOCUMENT = "addDocument",
    PUT_DOCUMENT = "editDocument",
    GET_STATUS_DOCUMENT = "getStatusDocument",
    GET_SECTIONS = "getSections",
    GET_SECTIONS_STAT = "getSectionsStat",
    GET_SECTION_BY_ID = "getSectionsByID",
    POST_SECTION = "addSection",
    PUT_SECTION = "editSection",
    DELETE_SECTION = "deleteSection",
    GET_COMPLIANCES = "getCompliances",
    GET_COMPLIANCE_BY_ID = "getComplianceById",
    PUT_COMPLIANCE = "editCompliance",
    ADD_COMPLIANCE = "addCompliance",
    DELETE_COMPLIANCE = "deleteCompliance",
    GET_SITES_BY_ID = "getSitesById",
    GET_SITE_BY_ID = "getSiteById",
    PROFILE = 'getProfile',
    GET_USER = 'getUser',
    GET_STATUS_EVALUATION = 'getStatusEvaluation',
    GET_STATUS_EVALUATION_STAT = 'getStatusEvaluationStat',
    GET_EVALUATION_BY_ID = 'getEvaluationById',
    PUT_EVALUATION = 'editEvaluation',
    GET_SITE = 'getSite',
    HISTORIC_EVALUATION = 'historicEvaluation',
    DOCUMENTS_WITH_SITE_STAT = 'documentWithSiteStat',
    DOCUMENTS_RECAP_EVAL_STAT = 'documentRecapEvalStat',
    GET_ID_THEME = 'getIdTheme',
    EXPORTS_POST = 'exportsPost',
    AFFECTATION_DOCUMENTS = 'affectationDocuments',
    IMPORT_FILE = 'importFile',
    GET_STATUS_IMPORT = 'getStatusImport',
    ADD_SITES_CLONE = 'addSiteClone',
    GET_ACTION_PLAN = 'getActionPlan',
    ADD_ACTION_PLAN = 'addActionPlan',
    PUT_ACTION_PLAN = 'editActionPlan',
    GET_ACTION_PLAN_BY_ID = 'getActionPlanById',
    ADD_TASK_AP = 'addTaskAP',
    GET_TASKS_AP = 'getTasksAP',
    PUT_TASKS_AP = 'editTasksAP',
    GET_TASK_AP_BY_ID = 'getTasksAPById',
    ADD_COMMENT_AP = 'addCommentAP',
    GET_COMMENT_AP = 'getCommentAP',
    GET_COMMENT_AP_BY_ID = 'getCommentAPById',
    PUT_COMMENT_AP = 'editCommentAP',
    DELETE_COMPLIANCE_AP = 'deleteComplianceAP',
    DELETE_EVALUATION_AP = 'deleteEvaluationAP',
    GET_USERS_AP = 'getUsersAP',
    POST_RANKED_SECTION = 'addRankedSection',
    PUT_RANKED_SECTION = 'editRankedSection',
    GET_CATEGORIES_TREE_FILTER = 'getCategoriesTreeFilter',
    DELETE_AP = 'deleteAP',
    DELETE_AP_TASK = 'deleteAPTask',
    DELETE_AP_COMMENT = 'deleteAPComment',
    UPLOAD_FILE = 'uploadFile',
    ATTACH_FILES = 'attachFiles',
    DELETE_FILE_DOCUMENT = 'deleteFileDocument',
    GET_MEDIA_ID = 'getMediaId',
    AFFECTATIONS_APPLICABLE = 'affectationsApplicable',
    EVALUATION_REPORT = 'evaluationReport',
    ASSIGN_SITES_BY_DOCUMENT = 'assignSitesByDocument',
    PUT_EVALUATION_DUPLICATE= 'editEvaluationDuplicate',
    SEND_DISCUSSION= 'sendDiscussion',
    UPLOAD_FILE_DISCUSSION= 'uploadFileDiscussion',
    GET_DISCUSSION_LIST= 'getDiscussionList',
    GET_MESSAGE_BY_DISCUSSION_ID= 'getMessageByDiscussionId',
    SEND_MESSAGE= 'sendMessage',
    READ_MESSAGE= 'readMessage',
    GET_FORUM= 'getForum',
    REPORT_MONTHLY_EVOLUTION= 'reportMonthlyEvolution',
    GET_EVALUATIONS = 'getEvaluations',
    SAVE_QUIZ = 'saveQuiz',
    GET_QUIZ = 'getQuiz',
    GET_STAT_CONFORMITE_SITE = 'getStatConformiteSite',
    GET_PROCESSING_TASKS = 'getProcessingTasks',
    GET_STAT_ECR = 'getStatECR',
    GET_SITE_BY_PARENT = 'getSiteByParent',
    GET_ROLE_USER_SITE = 'getRoleUserSite',
    DESAFFECT_DOCUMENTS = 'desaffectDocuments',
    DELETE_DOCUMENT_SITE = 'deleteDocumentSite',
    AFFECTATIONS_LIST = 'affectationsList',
    GLOBAL_CONFORMITY_RATP = 'globalConformityRatp',
    DOCUMENTS_number_STATUS_STAT_RATP = 'documentNumberStatusStatRatp',
    DELETE_SITE = 'deleteSite',
    DELETE_CUSTOMER = 'deleteCustomer',
    GET_ACTION_PLAN_REPORT = 'getActionPlanReport',
    GET_STATICS_EVALUATIONS = 'getStaticsEvaluations',
    GET_DOMAINS_WITH_STATUS = 'getDomainsWithStatus',
    GET_ALL_EVALUATION_STATUS = 'getAllEvaluationStatus'
}

enum Mutations {
    // mutation types
    SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
    PURGE_AUTH = "logOut",
    SET_AUTH = "setAuth",
    SET_USER = "setUser",
    SET_PASSWORD = "setPassword",
    SET_ERROR = "setError",
    SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
    SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
    RESET_LAYOUT_CONFIG = "resetLayoutConfig",
    OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
    OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
    SET_THEME_MODE_MUTATION = "setThemeModeMutation",
    SET_SITE = "setSite",
    SET_ID_THEME = "setIdTheme",
    SET_TOOLS = "setTools",
}

export {Actions, Mutations};
